import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from 'ag-grid-enterprise';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const PROD_URL = 'app.fundre.co';
const AG_GRID_LICENSE =
  'Using_this_{AG_Grid}_Enterprise_key_{AG-063473}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{FundRE_Limited}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{FundRE_Limited}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{FundRE_Limited}_need_to_be_licensed___{FundRE_Limited}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{15_September_2025}____[v3]_[01]_MTc1Nzg5MDgwMDAwMA==fceb03883a470792812a292eb46f93e8';

// Avoid non prod environments to be tracked by Plausible
if (window.location.host !== PROD_URL) {
  window.localStorage.plausible_ignore = true;
}

if (environment.isProdMode) {
  enableProdMode();
}

LicenseManager.setLicenseKey(AG_GRID_LICENSE);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
